import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './Button.module.scss';

const Button = ({ children, type, ...props }) => (
  <button className={cn(styles.component, styles[type])} {...props}>
    {children}
  </button>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['contained', 'outlined', 'clear'])
};

Button.defaultProps = {
  type: 'contained'
};

export default Button;

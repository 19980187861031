import fetch from 'cross-fetch';

export default {
  getSubjects: async function () {
    try {
      const response = await fetch(`/api/v1/subjects`);
      return await response.json();
    } catch (error) {
      console.error('Failed to fetch subjects:', error);
      return [];
    }
  },
  getGraph: async function (subject) {
    try {
      const response = await fetch(`/api/v1/subjects/${subject}`);
      return await response.json();
    } catch (error) {
      console.error('Failed to fetch graph:', error);
      return { nodes: [], links: [] };
    }
  }
};

import api from 'api';

export const FETCH_SUBJECTS = 'subjects/FETCH_SUBJECTS';
export const RECEIVE_SUBJECTS = 'subjects/RECEIVE_SUBJECTS';

// ------------------------------------
// Initial state
// ------------------------------------

const initialState = {
  items: [],
  pending: false
};

// ------------------------------------
// Reducer
// ------------------------------------

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUBJECTS:
      return {
        ...state,
        pending: true
      };

    case RECEIVE_SUBJECTS:
      const { subjects } = action;
      return {
        ...state,
        items: subjects,
        pending: false
      };

    default:
      return state;
  }
};

// ------------------------------------
// Actions
// ------------------------------------

export const fetchSubjects = () => {
  return async dispatch => {
    dispatch({ type: FETCH_SUBJECTS });
    const subjects = await api.getSubjects();
    return dispatch({
      type: RECEIVE_SUBJECTS,
      subjects
    });
  };
};

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'components/Icon';
import styles from './RadioButton.module.scss';

const RadioButton = ({ label, checked, disabled, ...props }) => (
  <label className={cn(styles.component, { [styles.disabled]: disabled })}>
    <input type="radio" checked={checked} disabled={disabled} {...props} />
    <Icon name={checked ? 'radio_button_checked' : 'radio_button_unchecked'} />
    <span>{label}</span>
  </label>
);

RadioButton.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool
};

RadioButton.defaultProps = {
  label: ''
};

export default RadioButton;

import api from 'api';

export const RESET_GRAPH = 'graph/RESET_GRAPH';
export const IMPORT_STATE = 'graph/IMPORT_STATE';

//
// Fetch/receive
//

export const FETCH_GRAPH = 'graph/FETCH_GRAPH';
export const RECEIVE_GRAPH = 'graph/RECEIVE_GRAPH';

//
// Nodes
//

export const ADD_NODE = 'graph/ADD_NODE';
export const UPDATE_NODE = 'graph/UPDATE_NODE';
export const REMOVE_NODE = 'graph/REMOVE_NODE';

//
// Links
//

export const ADD_LINK = 'graph/ADD_LINK';
export const UPDATE_LINK = 'graph/UPDATE_LINK';
export const REMOVE_LINK = 'graph/REMOVE_LINK';

// ------------------------------------
// Initial state
// ------------------------------------

const initialState = {
  subject: null,
  nodes: [],
  links: [],
  pending: false
};

// ------------------------------------
// Reducer
// ------------------------------------

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_GRAPH: {
      return { ...initialState };
    }

    case IMPORT_STATE: {
      return { ...action.state };
    }

    //
    // Fetch/receive
    //

    case FETCH_GRAPH: {
      return { ...state, pending: true };
    }

    case RECEIVE_GRAPH: {
      return {
        ...state,
        subject: action.subject,
        nodes: action.nodes,
        links: action.links,
        pending: false
      };
    }

    //
    // Nodes
    //

    case ADD_NODE: {
      if (state.nodes.some(node => node.id === action.node.id)) {
        console.error('Warning: attempting to add node with existing ID');
        return state;
      }

      return {
        ...state,
        nodes: [...state.nodes, action.node]
      };
    }

    case UPDATE_NODE: {
      return {
        ...state,
        nodes: state.nodes.map(node =>
          node.id === action.node.id ? action.node : node
        )
      };
    }

    case REMOVE_NODE: {
      return {
        ...state,
        nodes: state.nodes.filter(node =>
          node.id !== action.id
        )
      };
    }

    //
    // Links
    //

    case ADD_LINK: {
      if (state.links.some(link => link.id === action.link.id)) {
        console.error('Warning: attempting to add link with existing ID');
        return state;
      }

      return {
        ...state,
        links: [...state.links, action.link]
      };
    }

    case UPDATE_LINK: {
      return {
        ...state,
        links: state.links.map(link =>
          link.id === action.link.id ? action.link : link
        )
      };
    }

    case REMOVE_LINK: {
      return {
        ...state,
        links: state.links.filter(link =>
          link.id !== action.id
        )
      };
    }

    //
    // Default
    //

    default:
      return state;
  }
};

// ------------------------------------
// Actions
// ------------------------------------

export const importState = state => {
  return dispatch => {
    let isValid = true;

    for (const property in initialState) {
      if (!state.hasOwnProperty(property)) {
        console.error('Aborting: invalid state object');
        isValid = false;
        break;
      }
    }

    if (isValid) {
      dispatch({
        type: IMPORT_STATE,
        state
      });
    }
  };
};

export const loadGraph = id => {
  return async dispatch => {
    dispatch({ type: FETCH_GRAPH });
    const { nodes, links } = await api.getGraph(id);
    return dispatch({
      type: RECEIVE_GRAPH,
      subject: parseInt(id),
      nodes,
      links
    });
  };
};

export const resetGraph = () => ({
  type: RESET_GRAPH
});

//
// Nodes
//

export const addNode = node => ({
  type: ADD_NODE,
  node
});

export const updateNode = node => ({
  type: UPDATE_NODE,
  node
});

export const removeNode = id => ({
  type: REMOVE_NODE,
  id
});

//
// Links
//

export const addLink = link => ({
  type: ADD_LINK,
  link
});

export const updateLink = link => ({
  type: UPDATE_LINK,
  link
});

export const removeLink = id => ({
  type: REMOVE_LINK,
  id
});

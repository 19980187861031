import { without } from "ramda";

export const ADD = 'filter/ADD';
export const REMOVE = 'filter/REMOVE';
export const RESET = 'filter/RESET';

// ------------------------------------
// Initial state
// ------------------------------------

const initialState = {
  items: []
};

// ------------------------------------
// Reducer
// ------------------------------------

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD:
      return {
        items: [...state.items, action.filter]
      };

    case REMOVE:
      return {
        items: without([action.filter], state.items)
      };

    case RESET:
      return {
        ...initialState
      };

    default:
      return state;
  }
};

// ------------------------------------
// Actions
// ------------------------------------

export const addFilter = filter => ({
  type: ADD,
  filter
});

export const removeFilter = filter => ({
  type: REMOVE,
  filter
});

export const resetFilters = () => ({
  type: RESET
});

import { useSelector } from 'react-redux';

function useSubject() {
  return useSelector(({ subjects, graph }) => {
    return graph.subject !== null
      ? subjects.items.find(subject => subject.id === graph.subject)
      : null;
  });
};

export default useSubject;

//
// Hierarchical links
//

export const DOMAIN_OF = 'DOMAIN_OF';
export const GOAL_OF = 'GOAL_OF';

//
// Sequential links
//

export const FOLLOWS = 'FOLLOWS';
export const COINCIDES = 'COINCIDES';
export const REQUIRES = 'REQUIRES';

export default Object.freeze({
  INDEX: {
    path: '/',
    name: 'Vak',
    color: 'red',
    exact: true
  },
  GOALS: {
    path: '/goals',
    name: 'Leerdoelen',
    color: 'blue'
  },
  CLUSTERS: {
    path: '/clusters',
    name: 'Clusters',
    color: 'orange'
  },
  TIMELINE: {
    path: '/timeline',
    name: 'Netwerk',
    color: 'green'
  }
});

import { combineReducers } from 'redux';
import subjects from './subjects';
import graph from './graph';
import filter from './filter';
import selection from './selection';

export default combineReducers({
  subjects,
  graph,
  filter,
  selection
});

import React, { Suspense, lazy, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';

import routes from './routes';
import { fetchSubjects } from 'store/modules/subjects';

import Header from 'components/Header';
import NavBar from 'components/NavBar';

const Index = lazy(() => import('pages'));
const Goals = lazy(() => import('pages/goals'));
const Clusters = lazy(() => import('pages/clusters'));
const Timeline = lazy(() => import('pages/timeline'));

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSubjects());
  });

  return (
    <Router>
      <Header />
      <NavBar />
      <Suspense fallback={<p>Pagina wordt geladen...</p>}>
        <Switch>
          <Route {...routes.INDEX} component={Index} />
          <Route {...routes.GOALS} component={Goals} />
          <Route {...routes.CLUSTERS} component={Clusters} />
          <Route {...routes.TIMELINE} component={Timeline} />
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </Suspense>
    </Router>
  );
};

export default App;

import { useParams } from 'react-router';

const parseId = id => parseInt(id);

export function useId() {
  const params = useParams();
  return parseId(params.id);
};

export function useIds() {
  const params = useParams();
  return params.ids.split(',').map(parseId);
};

import { dissoc, includes } from 'ramda'
import { v4 as uuid, parse } from 'uuid';
import { SUBJECT, DOMAIN, GOAL } from 'const/nodes';
import { DOMAIN_OF, GOAL_OF, FOLLOWS, COINCIDES } from 'const/links';

/**
 * Return nodes and links that represent a hierarchical tree structure.
 * @param {Object} graph
 * @returns {Object}
 */
export const asTree = graph => {
  const nodes = filterByType([SUBJECT, DOMAIN, GOAL], graph.nodes)
    .map(node => dissoc('unrelated', node));
  const links = filterByType([DOMAIN_OF, GOAL_OF], graph.links);
  return { nodes, links };
};

/**
 * Return all direct sources of node.
 * @param {Object} node
 * @param {Object} graph
 * @returns {Array}
 */
export const sourcesOf = (node, graph) => {
  const { nodes, links } = graph;
  return links
    .filter(link => link.target === node.uuid)
    .map(link => sourceOf(link, nodes));
};

/**
 * Return all direct targets of node.
 * @param {Node} node
 * @param {Object} graph
 */
export const targetsOf = (node, graph) => {
  const { nodes, links } = graph;
  return links
    .filter(link => link.source === node.uuid)
    .map(link => targetOf(link, nodes));
};

/**
 * Returns all predecessors of target node.
 * @param {Object} node Target node
 * @param {Object} graph Graph object
 * @returns {Array}
 */
export const predecessorsOf = (node, graph) => {
  const targets = targetsOf(node, graph);
  return [
    ...targets,
    ...targets.flatMap(target => predecessorsOf(target, graph))
  ];
};

/**
 * Returns all successors of target node.
 * @param {Object} node Target node
 * @param {Object} graph Graph object
 * @returns {Array}
 */
export const successorsOf = (node, graph) => {
  const sources = sourcesOf(node, graph);
  return [
    ...sources,
    ...sources.flatMap(source => successorsOf(source, graph))
  ];
};

/**
 * True when first node precedes the second, false otherwise.
 * @param {Object} first First node
 * @param {Object} second Second node
 * @param {Object} graph Graph object
 * @returns {Boolean}
 */
export const precedes = (first, second, graph) => {
  return includes(first, predecessorsOf(second, graph));
};

/**
 * True when first node succeeds the second, false otherwise.
 * @param {Object} first First node
 * @param {Object} second Second node
 * @param {Object} graph Graph object
 * @returns {Boolean}
 */
export const follows = (first, second, graph) => {
  return includes(first, successorsOf(second, graph));
};

/**
 * Returns all connected nodes (sources and targets) of this node.
 * @param {Object} node Target node
 * @param {Object} graph Graph object
 * @returns {Array}
 */
export const neighboursOf = (node, graph) => {
  const sources = sourcesOf(node, graph);
  const targets = targetsOf(node, graph);
  return [...sources, ...targets];
};

/**
 * Returns the parent node for target node (hierarchical mode only).
 * @param {Object} node Target node
 * @param {Object} graph Graph object
 * @returns {Object}
 */
export const parentOf = (node, graph) => {
  const links = filterByType([GOAL_OF, DOMAIN_OF], graph.links);
  return targetsOf(node, { ...graph, links })[0] || null;
};

/**
 * Returns the child nodes for target node (hierarchical mode only).
 * @param {Object} node Target node
 * @param {Object} graph Graph object
 * @returns {Object}
 */
export const childrenOf = (node, graph) => {
  const links = filterByType([GOAL_OF, DOMAIN_OF], graph.links);
  return sourcesOf(node, { ...graph, links });
};

/**
 * Returns a list of goal nodes connected to this node.
 * @param {Object} domain Target node
 * @param {Object} graph Graph object
 * @returns {Array}
 */
export const goalsOf = (node, graph) => {
  const { nodes, links } = graph;
  return links
    .filter(link => link.type === GOAL_OF)
    .filter(link => link.target === node.uuid)
    .map(link => sourceOf(link, nodes));
};

/**
 * True if node has Goal nodes connected to it.
 * @param {Object} node Target node
 * @param {Array} links Collection of links
 * @returns {Boolean}
 */
export const hasGoals = (node, links) => {
  return links
    .filter(link => link.type === GOAL_OF)
    .some(link => link.target === node.uuid);
};

/**
 * Convenience method which tells whether node is a cluster (i.e.:
 * it is of type Domain and has Goals attached to it).
 * @param {Object} node Target node
 * @param {Array} links Collection of links
 * @returns {Boolean}
 */
export const isCluster = (node, links) => {
  return node.type === DOMAIN && hasGoals(node, links);
}

/**
 * Returns a graph of all goal nodes that are attached to the specified domain
 * node and any sequential links between them.
 * @param {Object} node Domain node
 * @param {Object} graph Graph object
 * @returns {Object}
 */
export const clusterOf = (node, graph) => {
  if (node.type !== DOMAIN) return {};

  const sequentialLinks = filterByType([FOLLOWS, COINCIDES], graph.links)
    .filter(link => !isExternal(link));

  const nodes = goalsOf(node, graph).sort(byUUID);
  const links = linksBetween(nodes, sequentialLinks);

  return { nodes, links };
};

/**
 * Return all nodes that that are connected to the source node
 * (direction is not important).
 * @param {Object} source Source node
 * @param {Object} graph Graph object
 * @returns {Array}
 */
export const connectedNodesOf = (source, graph) => {
  const connectedNodes = [];

  const visit = node => {
    connectedNodes.push(node);
    neighboursOf(node, graph)
      .filter(neighbour => !includes(neighbour, connectedNodes))
      .forEach(neighbour => visit(neighbour));
  };

  visit(source);
  return connectedNodes;
};

/**
 * True if there is a path between two nodes.
 * @param {Object} fist First node
 * @param {Object} second Second node
 * @param {Object} graph Graph object
 * @returns {Boolean}
 */
export const areConnected = (first, second, graph) => {
  const connectedNodes = connectedNodesOf(first, graph);
  return includes(second, connectedNodes);
};

/**
 * A graph is connected when there is a path from any node to any other
 * node in the graph (ignoring direction). This is computed by
 * recursively traversing adjacent nodes from an arbitrarily chosen
 * start node. The number of visited nodes should be equal to the total
 * number of nodes in the graph.
 *
 * https://mathworld.wolfram.com/ConnectedGraph.html
 *
 * @param {Object} graph Graph object
 * @returns {Boolean}
 */
export const isConnected = graph => {
  const connectedNodes = connectedNodesOf(graph.nodes[0], graph);
  return connectedNodes.length === graph.nodes.length;
};

/**
 * A graph is linear when there are no disconnected nodes (unless they are
 * unrelated) and there is exactly one path from start to end (using sequential
 * links only). In other words, the number of links (FOLLOWS, COINCIDES only)
 * should equal the number of nodes minus 1 and each node has at most one
 * incoming and one outgoing link of type FOLLOWS.
 *
 * https://mathworld.wolfram.com/PathGraph.html
 * @param {Object} graph Graph object
 * @returns {Boolean}
 */
export const isLinear = graph => {
  const nodes = graph.nodes.filter(node => !isUnrelated(node));
  const links = filterByType([FOLLOWS, COINCIDES], graph.links);
  const linksOfTypeFollows = filterByType(FOLLOWS, graph.links);
  return (
    links.length === nodes.length - 1 &&
    nodes.every(node =>
      linksTo(node, linksOfTypeFollows).length <= 1 &&
      linksFrom(node, linksOfTypeFollows).length <= 1
    )
  );
};

/**
 * True if there exists a node which has multiple sources or targets.
 * @param {Object} graph Graph object
 * @returns {Boolean}
 */
export const hasConflict = graph => {
  const { nodes } = graph;
  const links = filterByType(FOLLOWS, graph.links);
  return nodes.some(
    node =>
      links.filter(link => link.source === node.uuid).length > 1 ||
      links.filter(link => link.target === node.uuid).length > 1
  );
};

/**
 * Find and return all clusters within graph.
 * @param {Object} graph Graph object
 * @returns {Array} array of clusters
 */
export const clustersOf = graph => graph.nodes
  // Filter for clusters (domains that have goals)
  .filter(node => isCluster(node, graph.links))
  // ... and extend these clusters with their own sub graph.
  .map(node => ({ ...node, graph: clusterOf(node, graph) }));

/**
 * Find and return all _linear_ clusters within graph.
 * @param {Object} graph Graph object
 * @returns {Array} array of linear clusters
 */
export const linearClustersOf = graph => clustersOf(graph)
  .filter(cluster => isLinear(cluster.graph));

/**
 * Returns graph size (i.e. the number of nodes).
 * @param {Object} graph Graph object
 * @returns {Number}
 */
export const sizeOf = graph => graph.nodes.length;

/**
 * Returns first (start) node of graph, link type doesn't matter here.
 * @param {Object} graph Graph object
 * @returns {Object}
 */
export const startOf = graph => {
  const { nodes, links } = graph;
  return nodes.find(
    node =>
      links.some(link => link.target === node.uuid) &&
      links.every(link => link.source !== node.uuid)
  );
};

/**
 * Returns last (end) node of graph, using only links of type FOLLOWS.
 * @param {Object} graph Graph object
 * @returns {Object}
 */
export const endOf = graph => {
  const links = filterByType(FOLLOWS, graph.links);
  return graph.nodes.find(
    node =>
      links.some(link => link.source === node.uuid) &&
      links.every(link => link.target !== node.uuid)
  );
};

/**
 * True if node is start of graph, false otherwise.
 * @param {Object} node Node object
 * @param {Object} graph Graph object
 * @returns {Boolean}
 */
export const isStartOf = (node, graph) => {
  const startNode = startOf(graph);
  return startNode.uuid === node.uuid;
};

/**
 * True if node is end of graph, false otherwise.
 * @param {Object} node Node object
 * @param {Object} graph Graph object
 * @returns {Boolean}
 */
export const isEndOf = (node, graph) => {
  const endNode = endOf(graph);
  return endNode.uuid === node.uuid;
};

/**
 * Returns the position of the node within the chain graph.
 * @param {Object} node Node object
 * @param {Object} graph Graph object
 * @returns {Boolean}
 */
export const orderOf = (node, graph) => {
  const linksOfTypeCoincides = filterByType(COINCIDES, graph.links);
  if (linksFrom(node, linksOfTypeCoincides).length) {
    const target = targetsOf(node, graph).pop();
    return orderOf(target, graph);
  } else {
    return predecessorsOf(node, graph).length;
  }
}

/**
 * True if there are links connected to node.
 * @param {Object} node Target node
 * @param {Array} links Collection of links
 * @returns {Boolean}
 */
export const hasLinks = (node, links) => {
  return links.some(link =>
    link.source === node.uuid || link.target === node.uuid
  );
};

/**
 * True if link is connected to node.
 * @param {Object} link Link object
 * @param {Object} node Node object
 * @returns {Boolean}
 */
export const isLinkOf = (link, node) => {
  return link.source === node.uuid || link.target === node.uuid;
};

/**
 * Return incoming links of target node.
 * @param {Object} node Target node
 * @param {Array} links Collection of links
 * @returns {Array}
 */
export const linksTo = (node, links) => {
  return links.filter(link => link.target === node.uuid);
};

/**
 * Return outgoing links of target node.
 * @param {Object} node Target node
 * @param {Array} links Collection of links
 * @returns {Array}
 */
export const linksFrom = (node, links) => {
  return links.filter(link => link.source === node.uuid);
};

/**
 * Return all links that are connected to given node(s).
 * @param {Object|Array} nodes A node or collection of nodes
 * @param {Array} links Collection of links
 * @returns {Array}
 */
export const linksWith = (nodes, links) => {
  if (!Array.isArray(nodes)) nodes = [nodes];
  return links.filter(({ source, target }) => {
    return nodes.some(node => node.uuid === source) ||
      nodes.some(node => node.uuid === target);
  });
};

/**
 * Given a set of nodes, find all links between nodes from this set.
 * @param {Array} nodes Collection of nodes
 * @param {Array} links Collection of links
 * @returns {Array}
 */
export const linksBetween = (nodes, links) => {
  return links.filter(({ source, target }) => {
    const matchSource = nodes.some(node => node.uuid === source);
    const matchTarget = nodes.some(node => node.uuid === target);
    return matchSource && matchTarget;
  });
};

/**
 * True if the node has the unrelated property.
 * @param {Object} node Target node
 * @returns {Boolean}
 */
export const isUnrelated = node => node.unrelated;

/**
 * Return node object that is the source of link argument.
 * @param {Object} link Target link
 * @param {Array} nodes Collection of nodes
 * @returns {Object}
 */
export const sourceOf = (link, nodes) => {
  return nodes.find(node => node.uuid === link.source);
};

/**
 * Return node object that is the target of link argument.
 * @param {Object} link Target link
 * @param {Array} nodes Collection of nodes
 * @returns {Object}
 */
export const targetOf = (link, nodes) => {
  return nodes.find(node => node.uuid === link.target);
};

/**
 * Return node objects referenced by link argument.
 * @param {Object} link Target link
 * @param {Array} nodes Collection of nodes
 * @returns {Array}
 */
export const nodesOf = (link, nodes) => {
  return [sourceOf(link, nodes), targetOf(link, nodes)];
};

/**
 * True if the node has the createdByUser property.
 * @param {Object} node Target node
 * @returns {Boolean}
 */
export const isCreatedByUser = node => node.createdByUser;

/**
 * True if the link has the external property.
 * @param {Object} link Target link
 * @returns {Boolean}
 */
export const isExternal = link => link.external;

/**
 * Generic function that filters a collection of objects by property.
 * @param {String} prop The property name
 * @param {String|Array} values The value(s) to filter for
 * @param {Array} collection Array of items
 * @returns {Array}
 */
export const filter = (prop, values, collection) => {
  if (!Array.isArray(values)) values = [values];
  return collection.filter(item => values.includes(item[prop]));
}

/**
 * Convenience method to filter by 'type' property
 * @param {String|Array} values The type(s) to filter for
 * @param {Array} collection Array of items
 * @returns {Array}
 */
export const filterByType = (values, collection) => {
  return filter('type', values, collection);
}

/**
 * Convenience method to filter by 'uuid' property
 * @param {String|Array} values The UUID(s) to filter for
 * @param {Array} collection Array of items
 * @returns {Array}
 */
export const filterByUUID = (values, collection) => {
  return filter('uuid', values, collection);
}

/**
 * Generic compare function based on uuid
 * @param {Object} left Object with uuid property
 * @param {Object} right Object with uuid property
 * @returns {Number}
 */
export const byUUID = (a, b) => a.uuid.localeCompare(b.uuid);

/**
 * Generate a random ID.
 * @returns {Number}
 */
export const randomId = () => {
  const array = parse(uuid());
  const buffer = Buffer.from(array);
  return buffer.readUInt32BE(0);
}

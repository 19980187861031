import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { useGraph, useSubject } from 'hooks';

import { loadGraph, resetGraph, importState } from 'store/modules/graph';
import { clearSelection } from 'store/modules/selection';
import { resetFilters } from 'store/modules/filter';

import Button from 'components/Button';
import { Select } from 'components/Input';
import Logo from './logo.png';

import styles from './Header.module.scss';

const useSubjects = () => {
  return useSelector(({ subjects }) => subjects.items.map(subject => ({
    value: subject.id,
    label: subject.name
  })).sort(byName));
};

const byName = (a, b) => a.label.localeCompare(b.label);

const areYouSure =
  'Weet je zeker dat je een nieuw vak wil laden? De huidige staat van het netwerk zal hiermee worden overschreven.';

const Header = () => {
  const allSubjects = useSubjects();
  const currentSubject = useSubject();
  const currentGraph = useGraph();

  const dispatch = useDispatch();
  const history = useHistory();

  function onSubjectSelected(e) {
    if (window.confirm(areYouSure)) {
      const id = e.target.value;

      if (id) {
        dispatch(loadGraph(id));
      } else {
        dispatch(resetGraph());
      }

      dispatch(clearSelection());
      dispatch(resetFilters());

      history.push('/');
    }
  }

  function onImportGraph(e) {
    const reader = new FileReader();

    reader.onload = e => {
      try {
        const data = JSON.parse(e.target.result);
        dispatch(importState(data));
        history.push('/');
      } catch (e) {
        console.error(e);
      }
    };

    try {
      reader.readAsText(e.target.files[0]);
    } catch (e) {
      // ignore
    }

    e.target.value = null;
  }

  function onExportGraph(e) {
    const json = encodeURIComponent(JSON.stringify(currentGraph));

    const a = document.createElement('a');
    a.href = `data:text/json;charset=utf-8,${json}`;
    a.download = `${currentSubject.name}.json`;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  function triggerFileInput() {
    document.getElementById('file').click();
  };

  return (
    <header className={styles.component}>
      <a href="/">
        <img src={Logo} className={styles.logo} alt="ThiemeMeulenhoff" />
      </a>
      <Select
        name="subject"
        placeholder="Kies een vak"
        options={allSubjects}
        selected={currentGraph.subject}
        onChange={onSubjectSelected}
      />
      <div className={styles.actions}>
        <input
          id="file"
          type="file"
          style={{ display: 'none' }}
          onChange={onImportGraph}
        />
        <Button type="clear" onClick={triggerFileInput}>
          Importeren
        </Button>
        <Button type="clear" onClick={onExportGraph}>
          Exporteren
        </Button>
      </div>
    </header>
  );
}

export default Header;

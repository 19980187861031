import React from 'react';
import PropTypes from 'prop-types';
import styles from './Select.module.scss';

const Select = ({ placeholder, options, selected, ...props }) => (
  <select
    className={styles.component}
    value={selected !== null ? selected : ''}
    {...props}
  >
    {placeholder && <option value="">{placeholder}</option>}
    {options.map(({ value, label }) => (
      <option key={value} value={value}>
        {label}
      </option>
    ))}
  </select>
);

Select.propTypes = {
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string
    })
  ).isRequired,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func
};

export default Select;

import React from 'react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import routes from 'routes';
import styles from './NavBar.module.scss';

const navItems = Object.values(routes);

const Curve = () => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M0,0 C0,13.254834 10.745166,24 24,24 L0,24 L0,0 Z"/>
  </svg>
);

const Navbar = () => (
  <nav className={styles.component}>
    {navItems.map(({ path, name, color, exact }) => (
      <NavLink
        key={path}
        to={path}
        exact={exact}
        className={cn(styles.item, styles[`item-${color}`])}
        activeClassName={styles.active}
      >
        <Curve />
        {name}
        <Curve />
      </NavLink>
    ))}
  </nav>
);

export default Navbar;

import { useEffect, useRef, useState } from 'react';

function useHover() {
  const ref = useRef(null);
  const [hover, setHover] = useState(null);

  const handleMouseEnter = e => {
    const x = e.clientX || e.pageX;
    const y = e.clientY || e.pageY;
    setHover({ x, y });
  };

  const handleMouseLeave = () => {
    setHover(null);
  };

  useEffect(() => {
    const node = ref.current;

    if (node) {
      node.addEventListener('mouseenter', handleMouseEnter);
      node.addEventListener('mouseleave', handleMouseLeave);

      return () => {
        node.removeEventListener('mouseenter', handleMouseEnter);
        node.removeEventListener('mouseleave', handleMouseLeave);
      };
    }
  });

  return [ref, hover];
}

export default useHover;

export const ADD_ITEM = 'selection/ADD_ITEM';
export const REMOVE_ITEM = 'selection/REMOVE_ITEM';
export const CLEAR_SELECTION = 'selection/CLEAR_SELECTION';

// ------------------------------------
// Initial state
// ------------------------------------

const initialState = {
  items: []
};

// ------------------------------------
// Reducer
// ------------------------------------

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_ITEM:
      return {
        items: [...state.items, action.id]
      };

    case REMOVE_ITEM:
      return {
        items: state.items.filter(id => id !== action.id)
      };

    case CLEAR_SELECTION:
      return {
        ...initialState
      };

    default:
      return state;
  }
};

// ------------------------------------
// Actions
// ------------------------------------

export const addToSelection = id => ({
  type: ADD_ITEM,
  id
});

export const removeFromSelection = id => ({
  type: REMOVE_ITEM,
  id
});

export const clearSelection = () => ({
  type: CLEAR_SELECTION
});
